define('ember-ux-sauce/components/uxs-menu/item', ['exports', 'ember-ux-sauce/templates/components/uxs-menu/item', 'ember-ux-sauce/mixins/clickable', 'ember-ux-sauce/mixins/styleable', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _item, _clickable, _styleable, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NavItem = Ember.Component.extend(_bemComponent.default, _clickable.default, _styleable.default, _testable.default, {
    // Attributes
    base: 'uxs-menu__item',
    layout: _item.default,
    // Computed
    showRightIcon: Ember.computed('hasRightIcon', 'rightIcon', 'hasSecondaryText', 'secondaryText', function () {
      return Ember.get(this, 'hasRightIcon') || Ember.get(this, 'rightIcon') || Ember.get(this, 'hasSecondaryText') || Ember.get(this, 'secondaryText');
    }),
    showLeftIcon: Ember.computed('hasLeftIcon', 'leftIcon', function () {
      return Ember.get(this, 'hasLeftIcon') || Ember.get(this, 'leftIcon');
    }),
    init: function init() {
      this.registerModifiers(['detail', 'horizontal']);
      this._super.apply(this, arguments);
    },
    click: function click() {
      this._super.apply(this, arguments);
      if (Ember.get(this, 'showingModal') === true) {
        Ember.set(this, 'showingModal', false);
      }
    }
  });

  NavItem.reopenClass({
    positionalParams: ['textLabel']
  });

  exports.default = NavItem;
});