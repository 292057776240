define('ember-ux-sauce/components/uxs-button', ['exports', 'ember-ux-sauce/templates/components/uxs-button', 'ember-ux-sauce/mixins/buttonable', 'ember-ux-sauce/mixins/clickable', 'ember-prop-types'], function (exports, _uxsButton, _buttonable, _clickable, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Button = Ember.Component.extend(_buttonable.default, _clickable.default, _emberPropTypes.default, {
    // Attributes
    layout: _uxsButton.default,
    tagName: 'button',
    init: function init() {
      this._super.apply(this, arguments);
      this.get('propTypes', {
        loading: _emberPropTypes.PropTypes.bool,
        loadingText: _emberPropTypes.PropTypes.string
      });
    }
  });

  Button.reopenClass({
    positionalParams: ['text']
  });

  exports.default = Button;
});