define('ember-ux-sauce/components/uxs-menu', ['exports', 'ember-ux-sauce/templates/components/uxs-menu', 'ember-ux-sauce/mixins/styleable', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsMenu, _styleable, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _styleable.default, _testable.default, {
    // Attributes
    base: 'uxs-menu',
    bordered: true,
    layout: _uxsMenu.default,
    // Methods
    init: function init() {
      this.registerModifiers(['detail', 'horizontal', 'bordered', '*style']);
      this._super.apply(this, arguments);
    },
    getDefaultProps: function getDefaultProps() {
      return {
        align: 'left',
        detail: false,
        bordered: true,
        style: null
      };
    },

    // Actions
    actions: {
      closeMobileNav: function closeMobileNav() {
        Ember.set(this, 'isShowingNav', false);
        return true;
      }
    }
  });
});