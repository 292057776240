define('ember-ux-sauce/mixins/buttonable', ['exports', 'ember-prop-types', 'ember-ux-sauce/mixins/accessible', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _emberPropTypes, _accessible, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_accessible.default, _bemComponent.default, _emberPropTypes.default, _testable.default, {
    // Attributes
    attributeBindings: ['type'],
    base: 'uxs-button',
    propTypes: {
      inline: _emberPropTypes.PropTypes.bool,
      mini: _emberPropTypes.PropTypes.bool,
      naked: _emberPropTypes.PropTypes.bool,
      selected: _emberPropTypes.PropTypes.bool,
      prefix: _emberPropTypes.PropTypes.bool,
      suffix: _emberPropTypes.PropTypes.bool,
      style: _emberPropTypes.PropTypes.string,
      text: _emberPropTypes.PropTypes.oneOfType([_emberPropTypes.PropTypes.string, _emberPropTypes.PropTypes.func])
    },
    // Computed
    // isAccent: equal('style', 'accent'),
    // isBlack: equal('style', 'black'),
    // isGray: equal('style', 'gray'),
    // isPrimary: equal('style', 'primary'),
    // isWhite: equal('style', 'white'),
    /**
     * Alias for the aria accesibility label
     */
    label: Ember.computed.alias('text'),
    /**
     * Default the component name to dasherized text
     * If used in block format text will be null, so return true
     * The name property should be set manually in block usage
     */
    name: Ember.computed('text', function () {
      var text = Ember.get(this, 'text');
      if (text) {
        return Ember.String.dasherize(Ember.get(this, 'text'));
      }
      return true;
    }),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['disabled', 'inline', 'loading', 'mini', 'naked', 'selected', '*style', '*size', 'prefix', 'suffix']);
    },

    /**
     * Init any property defaults
     */
    getDefaultProps: function getDefaultProps() {
      return {
        style: null,
        loadingText: 'Loading…'
      };
    }
  });
});