define('ember-ux-sauce/components/uxs-icon', ['exports', 'ember-prop-types', 'ember-ux-sauce/templates/components/uxs-icon', 'ember-ux-sauce/mixins/buttonable', 'ember-ux-sauce/mixins/clickable'], function (exports, _emberPropTypes, _uxsIcon, _buttonable, _clickable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FormTip = Ember.Component.extend(_buttonable.default, _clickable.default, {
    // Attributes
    base: 'uxs-icon',
    layout: _uxsIcon.default,
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['disabled', '*size', '*style', 'inline']);
      Ember.set(this, 'propTypes', {
        icon: _emberPropTypes.PropTypes.string,
        disabled: _emberPropTypes.PropTypes.boolean,
        style: _emberPropTypes.PropTypes.string,
        size: _emberPropTypes.PropTypes.string
      });
    },
    getDefaultProps: function getDefaultProps() {
      return {
        style: null,
        disabled: false,
        size: 'medium'
      };
    }
  });

  FormTip.reopenClass({
    positionalParams: ['icon']
  });

  exports.default = FormTip;
});