define('ember-ux-sauce/components/uxs-pagination-controls', ['exports', 'ember-ux-sauce/templates/components/uxs-pagination-controls', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/mixins/testable'], function (exports, _uxsPaginationControls, _bemComponent, _testable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _testable.default, {
    // Attributes
    base: 'uxs-pagination',
    layout: _uxsPaginationControls.default,
    resultsText: "Results per page",
    countPrefix: "",
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*style']);
    },

    /**
     * Init any property defaults
     */
    getDefaultProps: function getDefaultProps() {
      return {
        style: 'light'
      };
    },

    // Computed
    pages: Ember.computed('total', 'size', function () {
      return Ember.get(this, 'total') / Ember.get(this, 'size');
    }),
    start: Ember.computed('page', 'size', function () {
      var page = Ember.get(this, 'page') - 1;
      var size = Ember.get(this, 'size');

      return page * size + 1;
    }),
    end: Ember.computed('start', 'size', 'total', function () {
      var start = Ember.get(this, 'start');
      var size = Ember.get(this, 'size');
      var total = Ember.get(this, 'total');

      return Math.min(total, start + size - 1);
    }),
    isPreviousButtonDisabled: Ember.computed.lte('page', 1),
    isNextButtonDisabled: Ember.computed('end', 'total', function () {
      return Ember.get(this, 'end') >= Ember.get(this, 'total');
    }),
    handleChange: function handleChange() {
      var props = Ember.getProperties(this, 'page', 'size');

      Ember.get(this, 'onChange')(props);
    },

    actions: {
      changeSize: function changeSize(e) {
        Ember.set(this, 'size', parseInt(e.target.value));
        Ember.set(this, 'page', 1);
        this.handleChange();
      },
      changePage: function changePage(page) {
        Ember.set(this, 'page', parseInt(page));
        this.handleChange();
      }
    }
  });
});