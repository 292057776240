define('ember-ux-sauce/components/uxs-list', ['exports', 'ember-ux-sauce/templates/components/uxs-list', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/mixins/testable'], function (exports, _uxsList, _bemComponent, _testable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _testable.default, {
    base: 'uxs-list',
    layout: _uxsList.default,
    // Computed
    parentStyle: Ember.computed('style', function () {
      var style = Ember.get(this, 'style');
      if (!Ember.isEmpty(style)) {
        return 'parent-' + style;
      }
    }),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['bordered', '*style']);
    },

    /**
     * Init any property defaults
     */
    getDefaultProps: function getDefaultProps() {
      return {
        style: 'white',
        bordered: false,
        lines: 1
      };
    }
  });
});