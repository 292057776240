define('ember-ux-sauce/components/uxs-icon-link', ['exports', 'ember-ux-sauce/templates/components/uxs-icon-link', 'ember-ux-sauce/mixins/buttonable', 'ember-ux-sauce/mixins/clickable', 'ember-ux-sauce/mixins/styleable', 'ember-prop-types'], function (exports, _uxsIconLink, _buttonable, _clickable, _styleable, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend(_buttonable.default, _clickable.default, _styleable.default, {
    // Attributes
    base: 'uxs-icon',
    layout: _uxsIconLink.default,
    // Computed
    icon: Ember.computed.alias('linkTitle'),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['accent', 'error', 'grey', 'primary', 'prefix', 'suffix', 'warning']);
      Ember.set(this, 'propTypes', {
        icon: _emberPropTypes.PropTypes.string
      });
    },
    getDefaultProps: function getDefaultProps() {
      return {
        style: null
      };
    }
  });
});