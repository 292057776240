define('ember-ux-sauce/mixins/alignable', ['exports', 'ember-prop-types'], function (exports, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_emberPropTypes.default, {
    // Attributes
    align: null,
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*align']);
      this.set('propTypes', {
        align: _emberPropTypes.PropTypes.oneOfType([_emberPropTypes.PropTypes.null, _emberPropTypes.PropTypes.string])
      });
    },

    registerModifiers: function registerModifiers() {
      if (this._super) {
        this._super.apply(this, arguments);
      }
    },
    /**
     * Init any property defaults
     */
    getDefaultProps: function getDefaultProps() {
      return {
        align: 'left'
      };
    }
  });
});