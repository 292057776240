define('ember-can/initializers/setup-ember-can', ['exports', 'ember-resolver'], function (exports, _emberResolver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  _emberResolver.default.reopen({
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    pluralizedTypes: {
      ability: 'abilities'
    }
  });

  function initialize() /* application */{}
  exports.default = { initialize: initialize };
});