define('ember-ux-sauce/components/uxs-list-notice', ['exports', 'ember-ux-sauce/templates/components/uxs-list-notice', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/mixins/testable'], function (exports, _uxsListNotice, _bemComponent, _testable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Notice = Ember.Component.extend(_bemComponent.default, _testable.default, {
    base: 'uxs-list__notice',
    // Computed
    hasText: Ember.computed.bool('text'),
    hasIcon: Ember.computed.bool('icon'),
    hasAction: Ember.computed.bool('actionText'),
    layout: _uxsListNotice.default,
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*style']);
    },

    // Actions
    actions: {
      action: function action() {
        var action = Ember.get(this, 'onClick');
        if (action) {
          action();
        }
      }
    }
  });

  Notice.reopenClass({
    positionalParams: ["text"]
  });

  exports.default = Notice;
});