define('ember-ux-sauce/components/uxs-datalist-item', ['exports', 'ember-ux-sauce/templates/components/uxs-datalist-item', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsDatalistItem, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _testable.default, {
    base: 'uxs-datalist__item',
    layout: _uxsDatalistItem.default,
    empty: 'N/A',
    // Computed
    hasLabel: Ember.computed.bool('label'),
    hasValue: Ember.computed.bool('value'),
    hasEmpty: Ember.computed.and('isNull', 'empty'),
    hasTip: Ember.computed.bool('tip'),
    isNull: Ember.computed.not('hasValue'),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['bordered', '*style']);
    }
  });
});