define('ember-ux-sauce/components/uxs-button-link', ['exports', 'ember-ux-sauce/templates/components/uxs-button-link', 'ember-ux-sauce/mixins/buttonable'], function (exports, _uxsButtonLink, _buttonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend(_buttonable.default, {
    // Attributes
    layout: _uxsButtonLink.default,
    role: 'link',
    // Computed
    text: Ember.computed.alias('linkTitle')
  });
});