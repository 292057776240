define('ember-ux-sauce/components/uxs-form-textarea', ['exports', 'ember-prop-types', 'ember-ux-sauce/mixins/accessible', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/templates/components/uxs-form-textarea'], function (exports, _emberPropTypes, _accessible, _testable, _bemComponent, _uxsFormTextarea) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextArea.extend(_accessible.default, _bemComponent.default, _emberPropTypes.default, _testable.default, {
    // Attributes
    base: 'uxs-form__input',
    layout: _uxsFormTextarea.default,
    // Computed
    textarea: true,
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.initModifiers();
      this.initModelComputedProperties();
      this.initPropTypes();
    },

    /**
     * Set the properties to bind to BEM modifier classes
     */
    initModifiers: function initModifiers() {
      this.registerModifiers(['disabled', 'error', 'inline', 'textarea', 'warning', '*style']);
    },

    /**
     * If this control has a model and name defined
     * we create an alias for the bound value.
     * We also create a one way computed property to
     * read the current validation state of the property.
     */
    initModelComputedProperties: function initModelComputedProperties() {
      var model = this.get('model'),
          propName = this.get('name');

      if (model && propName) {
        Ember.defineProperty(this, 'validator', Ember.computed.oneWay('model.validations.attrs.' + propName));
        // map the value to mode.property - this can be overridden by passing value
        // property into this component
        Ember.defineProperty(this, 'value', Ember.computed.alias('model.' + propName));
      }
    },

    /**
     * Set the prop type definitions
     */
    initPropTypes: function initPropTypes() {
      Ember.set(this, 'propTypes', {
        rows: _emberPropTypes.PropTypes.number,
        cols: _emberPropTypes.PropTypes.number,
        placeholder: _emberPropTypes.PropTypes.string,
        disabled: _emberPropTypes.PropTypes.boolean,
        maxlength: _emberPropTypes.PropTypes.number,
        tabindex: _emberPropTypes.PropTypes.number,
        wrap: _emberPropTypes.PropTypes.boolean,
        readonly: _emberPropTypes.PropTypes.boolean,
        autofocus: _emberPropTypes.PropTypes.boolean,
        spellcheck: _emberPropTypes.PropTypes.boolean
      });
    },
    getDefaultProps: function getDefaultProps() {
      return {
        rows: 6
      };
    }
  });
});