define('ember-ux-sauce/components/uxs-text', ['exports', 'ember-ux-sauce/templates/components/uxs-text', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/mixins/testable', 'ember-prop-types'], function (exports, _uxsText, _bemComponent, _testable, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Text = Ember.Component.extend(_bemComponent.default, _testable.default, {
    // Attributes
    base: 'uxs-text',
    layout: _uxsText.default,
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*size', '*style', '*weight']);

      Ember.set(this, 'propTypes', {
        size: _emberPropTypes.PropTypes.string,
        style: _emberPropTypes.PropTypes.string,
        weight: _emberPropTypes.PropTypes.string
      });
    },
    getDefaultProps: function getDefaultProps() {
      return {
        size: '',
        style: '',
        weight: ''
      };
    }
  });

  Text.reopenClass({
    positionalParams: ['text']
  });

  exports.default = Text;
});