define('ember-ux-sauce/components/uxs-list-item', ['exports', 'ember-ux-sauce/templates/components/uxs-list-item', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/mixins/clickable', 'ember-ux-sauce/mixins/testable'], function (exports, _uxsListItem, _bemComponent, _clickable, _testable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _clickable.default, _testable.default, {
    base: 'uxs-list-item',
    layout: _uxsListItem.default,
    // Computed
    parentStyle: Ember.computed('style', function () {
      var style = Ember.get(this, 'style');
      if (!Ember.isEmpty(style)) {
        return 'parent-' + style;
      }
    }),
    hasOneLine: Ember.computed.equal('lines', 1),
    hasTwoLines: Ember.computed.equal('lines', 2),
    hasThreeLines: Ember.computed.equal('lines', 3),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['bordered', '*style', 'hasOneLine:one-line', 'hasTwoLines:two-lines', 'hasThreeLines:three-lines']);
    },
    getDefaultProps: function getDefaultProps() {
      return {
        lines: 1,
        bordered: false
      };
    }
  });
});