define('ember-can/index', ['exports', 'ember-can/ability', 'ember-can/mixins/can', 'ember-can/computed', 'ember-can/services/can'], function (exports, _ability, _can, _computed, _can2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.computed = exports.CanService = exports.CanMixin = exports.Ability = undefined;
  exports.Ability = _ability.default;
  exports.CanMixin = _can.default;
  exports.CanService = _can2.default;
  exports.computed = _computed.default;
});