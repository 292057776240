define('ember-can/services/can', ['exports', 'ember-can/utils/normalize'], function (exports, _normalize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parse: function parse(name) {
      return (0, _normalize.default)(name);
    },
    build: function build(abilityName, model, properties) {
      var ability = Ember.getOwner(this).lookup('ability:' + abilityName);
      (true && !(ability) && Ember.assert('No ability type found for ' + abilityName, ability));

      // see if we've been given properties instead of model

      if (!properties && model && !(model instanceof Ember.Object)) {
        properties = model;
        model = null;
      }

      if (model) {
        ability.set("model", model);
      }

      if (properties) {
        ability.setProperties(properties);
      }

      return ability;
    },
    can: function can(abilityString, model, properties) {
      var _parse = this.parse(abilityString),
          abilityName = _parse.abilityName,
          propertyName = _parse.propertyName;

      var ability = this.build(abilityName, model, properties);

      return ability.get(propertyName);
    },
    cannot: function cannot(abilityString, model, properties) {
      return !this.can(abilityString, model, properties);
    }
  });
});