define('ember-ux-sauce/mixins/styleable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNameBindings: ['styleClass'],
    // Computed
    styleClass: Ember.computed('style', function () {
      var base = Ember.get(this, 'base'),
          style = Ember.get(this, 'style');
      if (style) {
        return base + '--' + style;
      }
    })
  });
});