define('ember-ux-sauce/components/uxs-table-row', ['exports', 'ember-ux-sauce/templates/components/uxs-table-row', 'ember-ux-sauce/mixins/textable'], function (exports, _uxsTableRow, _textable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_textable.default, {
    base: 'uxs-table__row',
    layout: _uxsTableRow.default,
    tagName: 'tr',
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['section', 'highlight']);
    }
  });
});