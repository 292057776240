define('ember-ux-sauce/components/uxs-form-error', ['exports', 'ember-prop-types', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/templates/components/uxs-form-error'], function (exports, _emberPropTypes, _testable, _bemComponent, _uxsFormError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FormError = Ember.Component.extend(_bemComponent.default, _emberPropTypes.default, _testable.default, {
    // Attributes
    base: 'uxs-form__error',
    layout: _uxsFormError.default,
    init: function init() {
      this.registerModifiers(['disabled']);
      this._super.apply(this, arguments);
      Ember.set(this, 'propTypes', {
        text: _emberPropTypes.PropTypes.string,
        disabled: _emberPropTypes.PropTypes.boolean
      });
    }
  });

  FormError.reopenClass({
    positionalParams: ['text']
  });

  exports.default = FormError;
});