define('ember-can/computed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    ability: function ability(type, resourceName) {
      if (arguments.length === 1) {
        resourceName = type;
      }

      return Ember.computed(resourceName, function () {
        var ability = Ember.getOwner(this).lookup('ability:' + type);

        (true && !(ability) && Ember.assert('No ability class found for ' + type, ability));


        var resource = Ember.get(this, resourceName);
        Ember.set(ability, 'model', resource);

        return ability;
      });
    }
  };
});