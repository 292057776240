define('ember-ux-sauce/components/uxs-pane', ['exports', 'ember-ux-sauce/templates/components/uxs-pane', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsPane, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _testable.default, {
    // Attributes
    base: 'uxs-window__pane',
    layout: _uxsPane.default,
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['aside', 'detail', 'full', 'index', 'main', 'single', 'splash',
      //
      'padded', '*type', '*style']);
    }
  });
});