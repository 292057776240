define('ember-ux-sauce/components/uxs-heading', ['exports', 'ember-ux-sauce/templates/components/uxs-heading', 'ember-ux-sauce/mixins/textable'], function (exports, _uxsHeading, _textable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Heading = Ember.Component.extend(_textable.default, {
    base: 'heading',
    layout: _uxsHeading.default,
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['giant', 'huge']);
    }
  });

  Heading.reopenClass({
    positionalParams: ['text']
  });

  exports.default = Heading;
});