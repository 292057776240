define('ember-ux-sauce/components/uxs-dl', ['exports', 'ember-ux-sauce/templates/components/uxs-dl', 'ember-ux-sauce/mixins/textable'], function (exports, _uxsDl, _textable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_textable.default, {
    base: 'uxs-dl',
    layout: _uxsDl.default,
    tagName: 'dl'
  });
});