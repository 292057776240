define('ember-ux-sauce/components/uxs-ol', ['exports', 'ember-ux-sauce/templates/components/uxs-ol', 'ember-ux-sauce/mixins/textable'], function (exports, _uxsOl, _textable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_textable.default, {
    base: 'uxs-ol',
    layout: _uxsOl.default,
    tagName: 'ol'
  });
});