define('ember-ux-sauce/components/uxs-ul', ['exports', 'ember-ux-sauce/templates/components/uxs-ul', 'ember-ux-sauce/mixins/textable'], function (exports, _uxsUl, _textable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_textable.default, {
    base: 'uxs-ul',
    layout: _uxsUl.default,
    tagName: 'ul'
  });
});