define('ember-ux-sauce/components/uxs-content', ['exports', 'ember-ux-sauce/templates/components/uxs-content', 'ember-ux-sauce/mixins/textable'], function (exports, _uxsContent, _textable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Content = Ember.Component.extend(_textable.default, {
    base: 'content',
    layout: _uxsContent.default
  });

  Content.reopenClass({
    positionalParams: ['text']
  });

  exports.default = Content;
});