define('ember-ux-sauce/components/uxs-button-group', ['exports', 'ember-prop-types', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/templates/components/uxs-button-group'], function (exports, _emberPropTypes, _testable, _bemComponent, _uxsButtonGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _emberPropTypes.default, _testable.default, {
    // Attribues
    base: 'uxs-button-group',
    debugBem: false,
    layout: _uxsButtonGroup.default,
    // Computed
    isAlignCenter: Ember.computed.equal('align', 'center'),
    isAlignLeft: Ember.computed.equal('align', 'left'),
    isAlignRight: Ember.computed.equal('align', 'right'),
    // Methods
    /**
     * init - state
     *
     * @return object  this
     */
    init: function init() {
      this._super.apply(this, arguments);

      this.registerModifiers(['isAlignCenter:center', 'disabled', 'inline', 'isAlignLeft:left', 'mini', 'isAlignRight:right']);

      this.set('propTypes', {
        align: _emberPropTypes.PropTypes.oneOfType([_emberPropTypes.PropTypes.null, _emberPropTypes.PropTypes.string]),
        disabled: _emberPropTypes.PropTypes.bool,
        inline: _emberPropTypes.PropTypes.bool,
        mini: _emberPropTypes.PropTypes.bool
      });
    },

    /**
     * Init any property defaults
     */
    getDefaultProps: function getDefaultProps() {
      return {
        disabled: false,
        inline: false,
        mini: false
      };
    }
  });
});