define('ember-ux-sauce/components/uxs-list-subheading', ['exports', 'ember-ux-sauce/templates/components/uxs-list-subheading', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsListSubheading, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Subheading = Ember.Component.extend(_bemComponent.default, {
    // Service
    subheadingState: Ember.inject.service('uxs-list-subheading-cache'),
    // Attributes
    base: 'uxs-list__subheading',
    layout: _uxsListSubheading.default,
    tagName: '',
    // Computed
    showSubheading: Ember.computed('text', 'sort', function () {
      var text = Ember.get(this, 'text'),
          sort = Ember.get(this, 'sort'),
          currentValue = Ember.get(this, 'subheadingState.value'),
          currentSort = Ember.get(this, 'subheadingState.sort');

      if (text !== currentValue || sort !== currentSort) {
        Ember.set(this, 'subheadingState.value', text);
        Ember.set(this, 'subheadingState.sort', sort);
        return true;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['bordered', '*style']);
    }
  });

  Subheading.reopenClass({
    positionalParams: ['text', 'sort']
  });

  exports.default = Subheading;
});