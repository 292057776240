define('ember-ux-sauce/components/uxs-detail', ['exports', 'ember-ux-sauce/templates/components/uxs-detail', 'ember-ux-sauce/mixins/textable'], function (exports, _uxsDetail, _textable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Detail = Ember.Component.extend(_textable.default, {
    base: 'detail',
    layout: _uxsDetail.default
  });

  Detail.reopenClass({
    positionalParams: ['text']
  });

  exports.default = Detail;
});