define('ember-can/mixins/can', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    canService: Ember.inject.service('can'),

    can: function can(abilityName, resource, properties) {
      return this.get('canService').can(abilityName, resource, properties);
    },
    cannot: function cannot(abilityName, resource, properties) {
      return !this.can(abilityName, resource, properties);
    }
  });
});