define('ember-ux-sauce/components/uxs-notice', ['exports', 'ember-ux-sauce/templates/components/uxs-notice', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/mixins/clickable', 'ember-ux-sauce/mixins/testable'], function (exports, _uxsNotice, _bemComponent, _clickable, _testable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Notice = Ember.Component.extend(_bemComponent.default, _clickable.default, _testable.default, {
    // Attributes
    base: 'uxs-notice',
    layout: _uxsNotice.default,
    // Computed
    hasText: Ember.computed.bool('text'),
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*style', 'truncated', 'hasText:has-text']);
    },
    getDefaultProps: function getDefaultProps() {
      return {
        style: 'default',
        truncated: true
      };
    }
  });

  Notice.reopenClass({
    positionalParams: ['text']
  });

  exports.default = Notice;
});