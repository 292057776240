define('ember-ux-sauce/components/uxs-table', ['exports', 'ember-ux-sauce/templates/components/uxs-table', 'ember-ux-sauce/mixins/textable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsTable, _textable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _textable.default, {
    // Attributes
    base: 'uxs-table',
    layout: _uxsTable.default,
    tagName: 'table',
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*style']);
    }
  });
});