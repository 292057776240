define('ember-ux-sauce/mixins/accessible', ['exports', 'ember-prop-types'], function (exports, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    attributeBindings: ['disabled', 'disabled:aria-disabled', 'label:aria-label', 'role'],
    /**
      Prevents the action from firing when true
      @property disabled
      @default  null
      @type     {boolean}
      @public
    */
    disabled: false,
    /**
      Sets the an aria-label attribute
      @property label
      @default  null
      @type     {(string)}
      @public
     */
    label: false,
    /**
     * Mixins Property Managment config
     *
     @property propTypes
     @type     {Object}
     @private
     */
    propTypes: {
      disabled: _emberPropTypes.PropTypes.bool,
      label: _emberPropTypes.PropTypes.oneOfType([_emberPropTypes.PropTypes.bool, _emberPropTypes.PropTypes.string, _emberPropTypes.PropTypes.func]),
      role: _emberPropTypes.PropTypes.oneOfType([_emberPropTypes.PropTypes.bool, _emberPropTypes.PropTypes.string])
    },
    /**
      Sets the role attribute
      @property role
      @default  null
      @type     {(string)}
      @public
     */
    role: false
  });
});