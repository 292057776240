define('ember-ux-sauce/components/uxs-table-heading', ['exports', 'ember-ux-sauce/templates/components/uxs-table-heading', 'ember-ux-sauce/mixins/textable'], function (exports, _uxsTableHeading, _textable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Heading = Ember.Component.extend(_textable.default, {
    base: 'uxs-table__heading',
    layout: _uxsTableHeading.default,
    tagName: 'th',
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['highlight', 'small', 'numeric', 'section']);
    }
  });

  Heading.reopenClass({
    positionalParams: ['text']
  });

  exports.default = Heading;
});